import React, { useContext, useEffect, useReducer, useState } from 'react';

import { FcGoogle } from 'react-icons/fc';
import { FaSquareFacebook } from 'react-icons/fa6';

import RightBlock from '../../components/SignUpLayout/RightBlock.js';
import SignUpForm from './SignUpForm.js';
import LogInForm from './LogInForm.js';

export default function GoogleSignIn({
  formGreetings,
  formSubGreetings,
  formType,
}) {
  // const appContext = useContext(AppContext);
  // const { setIsLoggedIn } = appContext;
  // const navigate = useNavigate();
  // const location = useLocation();
  // const onVerifyToken = async (values) => {
  //   try {
  //     setLoader(true);
  //     const response = await verifyToken(values);
  //     const data = response.data;
  //     if (response.status === 200) {
  //       localStorage.setItem('token', data.token);
  //       setIsLoggedIn(true);
  //       navigate(location?.state?.from || '/');
  //       toast.success(data.message);
  //     } else {
  //       setIsWrongOtp(true);
  //       setErrorMsg('Wrong OTP');
  //     }
  //   } catch (error) {
  //     setLoader(false);
  //     toast.error(error?.response?.data?.message, { id: '001' });
  //   }
  // };
  // const responseMessage = (response) => {
  //   const values = { token: response.credential };
  //   onVerifyToken(values);
  // };
  // const errorMessage = (error) => {
  //   console.log();
  // };
  // const formik = useFormik({
  //   initialValues: { email: '', keepLoggedIn: false },
  //   validationSchema: WltSignInValidation,
  //   onSubmit: async (values) => {
  //     const { email } = values;
  //     try {
  //       setLoader(true);
  //       const res = await loginApi({ email });
  //       if (res.status === 200) {
  //         toast.success(res.data.message);
  //         navigate('/otp', {
  //           state: {
  //             email: formik.values.email,
  //             keepLoggedIn: formik.values.keepLoggedIn,
  //           },
  //         });
  //       }
  //     } catch (error) {
  //       setLoader(false);
  //       const message =
  //         error.response.data.message || error.response.statusText;
  //       toast.error(message, { id: 'error' });
  //     }
  //   },
  // });
  // const handleChange = (e) => {
  //   e.preventDefault();
  //   formik.handleChange(e);
  // };
  // const [loader, setLoader] = useState(false);
  return (
    <>
      <section className="sign-in w-full h-full min-h-svh max-h-svh overflow-hidden flex items-center justify-center bg-white">
        <div className="sign-in-left flex  items-center flex-grow w-1/2 ">
          <section className="flex flex-col gap-2 max-h-screen min-h-screen overflow-y-auto scrollbar w-full bg-[#FAFAFE] px-4 py-10  relative">
            <div className="welMessage space-y-3">
              <h1 className="text-2xl font-medium text-center capitalize">
                {formGreetings}
              </h1>
              <p className="text-sm font-normal text-center max-w-80 text-primary_v2 mx-auto">
                {formSubGreetings}
              </p>
            </div>
            <div className="logoContainer flex justify-center mt-4">
              <div className="inline-flex mx-auto gap-2 items-center">
                <div className="rounded-full overflow-hidden">
                  <img
                    src={require('../../Assets/logo/odds_logo_blue.png')}
                    className="size-10 object-contain"
                  />
                </div>
                <p className="text-lg font-semibold text-coalBlack">MODO</p>
              </div>
            </div>
            <div className="signInFormContainer mt-16 max-w-sm mx-auto w-full lg:max-w-[400px] lg:min-w-[400px] ">
              <div className="signInFormField mt-8">
                {formType == 'SIGNUP' ? <SignUpForm /> : <LogInForm />}
              </div>
            </div>
          </section>
        </div>
        <RightBlock />
      </section>
    </>
  );
}

function SignInWithGoogleCard({ platform, icon }) {
  return (
    <div className="inline-flex justify-center items-center gap-2 capitalize text-sm text-primary_v2 font-medium bg-inherit  min-w-28 text-center  rounded-xl  cursor-pointer">
      {icon == 'google' ? <FcGoogle /> : <FaSquareFacebook />}
      {platform}
    </div>
  );
}
