import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../Pages/404';
import PrivateRoute from './privateRoute';
import PublicRoute from './PublicRoute';
import GoogleSignIn from '../Pages/Account/googleSignIn';
import { byAuth } from '../apis/index.js';
import Index from '../Pages/DashboardOdds/Index.js';
import CreditIndex from '../Pages/CreditManagement/CreditIndex.js';
import DoctorIndex from '../Pages/DoctorManagement/Index.js';
import HospitalManagement from '../Pages/HospitalManagement/HosipatManagement.js';
import PayoutIndex from '../Pages/payoutManagement/PayoutIndex.js';
import ReportsTab from '../Pages/Reports/ReportsIndex.js';
import HistoryIndex from '../Pages/consultationHistory/HistoryIndex.js';
import NotificationOdds from '../Pages/NotificationOdds/NotificationOdds.js';
import DisputedConsultation from '../Pages/DisputedConsultation/index.js';
import SettingsManagement from '../Pages/oddssettings/SettingsManagement.js';

export default function MainRouter() {
  const [loggedInUser, setLoggedInUser] = useState();

  const getLoggedInUser = async () => {
    try {
      const res = await byAuth();
      if (res.status === 200) {
        setLoggedInUser(res.data.user[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/sign-in"
          element={
            <PublicRoute>
              <GoogleSignIn
                formGreetings={'Welcome Back !'}
                formSubGreetings={'Enter your email and password to sign in'}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          }
        />
        <Route
          path="/paymentmanagement"
          element={
            <PrivateRoute>
              <CreditIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="/hospitalmanagement"
          element={
            <PrivateRoute>
              <HospitalManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/doctormanagement"
          element={
            <PrivateRoute>
              <DoctorIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="/payoutmanagement"
          element={
            <PrivateRoute>
              <PayoutIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="notification"
          element={
            <PrivateRoute>
              <NotificationOdds />
            </PrivateRoute>
          }
        />
        <Route
          path="settings"
          element={
            <PrivateRoute>
              <SettingsManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <ReportsTab />
            </PrivateRoute>
          }
        />
        <Route
          path="/consultationHistory"
          element={
            <PrivateRoute>
              <HistoryIndex />
            </PrivateRoute>
          }
        />
        <Route
          path="/disputedconsultation"
          element={
            <PrivateRoute>
              <DisputedConsultation />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
      </Routes>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{ zIndex: '99999' }}
      />
    </>
  );
}
