import React, { useContext, useState } from 'react';
import { IoNotifications } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { AppContext } from '../../appContext';


function SearchHeader({ titleTab }) {
  const { userDetails } = useContext(AppContext);

  const URL = window.location;

  let NOOFHOS = 'block';
  if (URL.pathname === '/') {
    NOOFHOS = 'hidden';
  }

  return (
    <section>
      <div className="justify-between items-center py-6 px-6 bg-white hidden lg:flex xl:px-10 sticky top-0 z-[899] border-b border-gray-100">
        <div className="w-fit flex gap-4 items-center xl:gap-6 ms-auto">
          <Link to={'/notification'} className="relative">
            <IoNotifications className="text-2xl text-gray-400 p-1.5 hover:bg-gray-50 rounded-full w-9 h-9 cursor-pointer" />
            <div className="w-2 h-2 bg-gray-300 absolute top-1.5 right-2 rounded-full"></div>
          </Link>
          <div className="flex items-center gap-2">
            <div className="w-10 h-10 bg-blue-400 rounded-full bg-avatar">
              {userDetails?.DoctorProfile?.coverPic ? (
                <img
                  src={userDetails?.DoctorProfile?.coverPic}
                  className="block object-cover rounded-full w-full h-full"
                />
              ) : null}
            </div>
            <p className="text-navLink baseText capitalize">
              {userDetails?.Profile?.userName}
            </p>
          </div>
        </div>
      </div>
      <div
        className={`flex  p-5 bg-blue-700 rounded-br-2xl rounded-bl-2xl relative bg-hero bg-cover bg-no-repeat  bg-left min-h-36  items-center ${NOOFHOS}`}
      >
        <div className="flex items-center flex-grow ">
          <h1 className="highlightText text-white   capitalize ms-40">
            Welcome,  {userDetails?.Profile?.userName}
          </h1>
        </div>
      </div>

      {/* title tab  start */}
      {titleTab.active && (
        <div
          className={`bg-white rounded-2xl px-4  flex items-center gap-4 relative -top-7 max-h-24 min-h-24 mx-6 ${NOOFHOS}`}
        >
          {titleTab?.imgBlock && (
            <div className="size-20 bg-gray-100 rounded-full inline-flex justify-center items-center relative -top-10">
              {titleTab.img}
            </div>
          )}
          <p
            className="headingText  inline-block capitalize
          "
          >
            {titleTab?.heading}
          </p>
          {titleTab?.btnBlock && (
            <div className="inline-block ms-auto">{titleTab?.btn}</div>
          )}
        </div>
      )}
    </section>
  );
}

SearchHeader.defaultProps = {
  titleTab: {
    active: false,
    heading: 'Default title',
    btnBlock: false,
    btn: null,
    imgBlock: false,
    img: null,
  },
};

export default SearchHeader;
