import React, { useRef, useState } from 'react';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { API_BASE_URL } from '../../utils/baseurl';
import PayoutReportstypesense from '../../components/TypesenseList/Report.js/Payout';
import ConsultationReportstypesense from '../../components/TypesenseList/Report.js/ConsultationHistory';
import CommisionReport from '../../components/TypesenseList/Report.js/CommisionReport';
function MonthlyEarnings() {
  const typesenseInitiate = () => {
    try {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
          nodes: [
            { host: process.env.REACT_APP_HOST_NAME_NEW, protocol: 'https' },
          ],
        },
        additionalSearchParameters: {
          collection: 'odds-monthly-commission-report',
          //   sort_by: `RegisteredDate:'desc'`,
          query_by: '*',
        },
      });
      return typesenseInstantsearchAdapter.searchClient;
    } catch (e) {
      console.error('Initialization failed: ', e);
      return null;
    }
  };
  const searchClient = typesenseInitiate();
  const [searchresult, setsearchresult] = useState(0);
  return (
    <div className="px-1">
      <section className="bg-white rounded-2xl p-5 my-6">
        <div className="flex justify-between items-center capitalize">
          <div className="flex flex-row items-center">
            <p className="baseText text-coalBlack">Monthly Earnings History</p>
          </div>
          <div>
            <a
              href={`${API_BASE_URL}/api/v1/export/excel?type=CommissionReport`}
              className="flex flex-row items-center gap-2 border rounded-lg border-grey-300 p-2"
            >
              <PiMicrosoftExcelLogo color="#6aa84f" />{' '}
              <p className="smallText text-navLink">Export Excel</p>
            </a>
          </div>
        </div>
        <div className="mt-10">
          <CommisionReport
            searchClient={searchClient}
            setsearchresult={setsearchresult}
          />
        </div>
      </section>
    </div>
  );
}

export default MonthlyEarnings;
