import { API_BASE_URL } from './baseurl';
import moment from 'moment';

const currDate = moment().format('YYYY-MM-DD');
export const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: '0 !important',
    minHeight: '0'
  }),
};
export const autoHeightText = (el) => {
  el.target.style.height = '69px';
  el.target.style.height = 1 + el.target.scrollHeight + 'px';
};
export const logoutOnJwtExpire = () => localStorage.clear();
export const isAuth = () => Boolean(getToken());
export const assignBucketUrl = (path) => {
  return encodeURI(path?.startsWith('uploads/') ? API_BASE_URL + '/' + path : path);
};
export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};
