import { useEffect, useState, useRef } from 'react';
import {
  SearchBox,
  InstantSearch,
  InfiniteHits,
  RefinementList,
  SortBy,
  Configure,
  useInstantSearch,
  Pagination,
  CurrentRefinements,
  Hits,
} from 'react-instantsearch';
import { EditProfileLoader } from '../../common/loader';
import '../globalSearch.css';
import TableListHeader from '../../TableList/TableListHeader';
import BigLoader from '../BigLoader';
import ReportcreditPurchaseList from '../../TableList/Report/CreditPurchase';

export default function CreditPurchaseReportstypesense({
  searchClient,
  setsearchresult,
}) {
  const [searchWindowClassName, setSearchWindowClassName] = useState('search-box-visible');
  const MainContainer= () => {
    const { status, refresh, results } = useInstantSearch();
    setsearchresult(results._rawResults[0].nbHits);
    return status === 'loading' || status === 'stalled' ? (
      <BigLoader />
    ) :  <Hits
      className="row list-style"
      hitComponent={(hit) => (
        <ReportcreditPurchaseList
          hit={hit.hit}
          columnWidth={`${100 / headers.length}%`}
        />
      )}
    />;
  };
  const headers = [
    {
      title: 'Hospital Name',
    },
    {
      title: 'Email',
    },
    {
      title: 'Transaction ID',
    },
    {
      title: 'Purchased Amount',
      display:'flex',
      justify:'center'
    },
    {
      title: 'Purchased On',
      display:'flex',
      justify:'center'

    },
  ];
  const NoResultsBoundary = ({ children, fallback }) => {
    const { results, error } = useInstantSearch();
    if (error) {
      setSearchWindowClassName('search-window-hidden');
      return (
        <div>
          <span className="d-flex align-items-center">
            <h5 className="m-1">Note: </h5>
            <p className="m-1"> Use Control/Command + F to search </p>
          </span>
        </div>
      );
    }

    if (!results.__isArtificial && results.nbHits === 0) {
      setSearchWindowClassName('search-window-visible');
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }
    setSearchWindowClassName('search-window-visible');
    return children;
  };

  const NoResults = () => {
    const { indexUiState } = useInstantSearch();
    return (
      <div className="h-40 flex items-center justify-center">
        <div className="text-center baseText">There were no results.</div>
      </div>
    );
  };
  return (
    <div className="col-12">
      <div className="global-search">
        <InstantSearch
          indexName="odds-creditpurchase"
          searchClient={searchClient}
        >
          <Configure
            hitsPerPage={10}
          />
         
          <CurrentRefinements />

          <div className="search-card-table-container relative overflow-hidden">
            <TableListHeader
              headers={headers}
              columnWidth={`${100 / headers.length}%`}
            />

            <NoResultsBoundary fallback={<NoResults />}>
              <div className="align-items-start search-container">
            
                <div className="col-12 search-card-main-window">
                  {/* <MainContainer /> */}
                  <Hits
      className="row list-style"
      hitComponent={(hit) => (
        <ReportcreditPurchaseList
          hit={hit.hit}
          columnWidth={`${100 / headers.length}%`}
        />
      )}
    />
                </div>
              </div>
            </NoResultsBoundary>
            <Pagination
              padding={2}
              showFirst={false}
              showLast={false}
              classNames={{
                list: 'flex items-center mt-10 w-full justify-end',
              }}
              translations={{
                previousPageItemText: 'Previous',
                nextPageItemText: 'Next',
              }}
            />
          </div>
        </InstantSearch>
      </div>
    </div>
  );
}
