import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate,} from 'react-router-dom';
import {
  userAuth,
} from '../../apis/index.js';
import {
  SignInValidation,
} from '../../components/Validation/index.js';

import { InputErrorMessage } from '../../components/errorMessages/index.js';
import { EditProfileLoader } from '../../components/common/loader.js';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';
import { AppContext } from '../../appContext/index.js';
import OneSignal from 'react-onesignal';

function LogInForm() {
  const {fetchUserProfile}=useContext(AppContext);
  // console.log(fetchUserProfile);
  const { userDetails, setUserDetails } = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState('password');
  const [token, setToken] = useState('');
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  // const getUserDetails = async () => {
  //   try {
  //     const res = await getProfileById(localStorage.getItem('userId'));
  //     if (res.status === 200) {
  //       setUserDetails(res.data.Profile);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }
  function handelForgotPasswordModal() {
    setForgotPasswordModal(!forgotPasswordModal);
  }

  const formik = useFormik({
    initialValues: { email: '', password: '', keepMeLoggedIn: false },
    validationSchema: SignInValidation,
    onSubmit: async (values) => {
      const { email, password } = values;
      onUserAuth(values);
    },
  });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const onUserAuth = async (payload) => {
    try {
      setLoader(true);
      const res = await userAuth(payload);
      if (res.status == 200) {
        localStorage.setItem('userId', res.data.userId);
        localStorage.setItem('token', res.data.token);
        fetchUserProfile();
        await runOneSignal(res.data.userId)
        navigate('/');
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
     
      setLoader(false);
      if (error.request.status == 500) {
        if (error.response.data.error == 'User login failed') {
          toast.error('User dose not exist');
        } else {
          toast.error(error.response.data.error);
        }
      }
    }
  };
  async function runOneSignal(id) {
    try {
    //  console.log(id)
     OneSignal.login(id)
      console.log('Login successful');
    } catch (error) {
      console.error('Error logging in:', error);
    }
  }
  
  return (
    <>
      <form className="LogInForm space-y-3 " onSubmit={handleSubmit}>
        <div className="relative">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
            Email
          </p>
          <input
            type="text"
            name="email"
            className={
              Boolean(formik.touched.email) && Boolean(formik.errors.email)
                ? 'form-control border-danger transparent-input-border'
                : 'form-control transparent-input-border'
            }
            id="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={handleChange}
          />
          <InputErrorMessage
            error={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="relative">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize after:content-['*'] after:ml-0.5 after:text-accent">
            password
          </p>
          <div className="relative">
            <input
              type={passwordType}
              name="password"
              className={
                Boolean(formik.touched.password) &&
                Boolean(formik.errors.password)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="password"
              placeholder="Password "
              value={formik.values.password}
              onChange={handleChange}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelPasswordType}
            >
              {passwordType == 'password' ? (
                <IoEyeOff />
              ) : passwordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={formik.touched.password && formik.errors.password}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className=" flex items-start ">
            <input
              type="checkbox"
              className="mt-0.5"
              id="keepMeLoggedIn"
              name="keepMeLoggedIn"
              checked={values.keepMeLoggedIn}
              onChange={handleChange}
            />
            <label
              className="text-xs text-primary_v2 ms-1 select-none"
              htmlFor="keepMeLoggedIn"
            >
              Remember Me
            </label>
          </div>
        </div>
        <div className="signButton !mt-[74px] space-y-2">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Log In'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </form>
    </>
  );
}

export default LogInForm;
